import React from 'react';

const About = () => {
  return (
    <div id="about-me" class="about-me">
      <h2 class="name">
        <span class="long-dash"></span>
        <span class="name-text">About me</span>
      </h2>
      <p>
      I started out by learning different programming languages, then used Ruby on Rails to create a discussion platform with country-specific forums that brings people together throughout Africa.
      </p>
      <p>
      Using Rails and JavaScript, I have built features ranging from content aggregation to real-time messaging, keeping performance and reliability top of mind. I prefer to break down complex problems and build streamlined systems, continually striving to learn and implement new tech to improve user experience.
      </p>
    </div>
  )
}

export default About;